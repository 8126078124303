import {
  formatDistance,
  type Locale,
} from 'date-fns';
import {
  de as dateFnsDe,
  enGB as dateFnsEn,
  es as dateFnsEs,
  fr as dateFnsFr,
  it as dateFnsIt,
  nl as dateFnsNl,
} from 'date-fns/locale';
import {
  getCurrency,
  getLocaleByCode,
  type LocaleCode,
} from 'localization';
import { assign } from 'lodash-es';

type NumberFormatOptions = Parameters<typeof Intl.NumberFormat>[1];
type CurrencyFormatOptions = Omit<NonNullable<NumberFormatOptions>, 'style'>;
type DateTimeFormatOptions = Parameters<typeof Intl.DateTimeFormat>[1];

const dateFnsLocaleMap: Record<LocaleCode, Locale> = {
  de: dateFnsDe,
  'de-at': dateFnsDe,
  'de-ch': dateFnsDe,
  'de-it': dateFnsDe,
  'de-li': dateFnsDe,
  'de-lu': dateFnsDe,
  en: dateFnsEn,
  es: dateFnsEs,
  fr: dateFnsFr,
  'fr-be': dateFnsFr,
  'fr-ch': dateFnsFr,
  'fr-lu': dateFnsFr,
  it: dateFnsIt,
  'it-ch': dateFnsIt,
  nl: dateFnsNl,
  'nl-be': dateFnsNl,
  uk: dateFnsEn,
};

const useFormatter = () => {
  const { locale } = useI18n();

  const currentIso = computed(() => getLocaleByCode(locale.value)?.iso);

  const formatCurrency = (value: number, options?: CurrencyFormatOptions) => {
    const defaultOptions = {
      currency: getCurrency(currentIso.value),
      style: 'currency',
    } satisfies NumberFormatOptions;

    return Intl.NumberFormat(currentIso.value, assign(defaultOptions, options)).format(value);
  };

  const formatNumber = (value: number, options?: NumberFormatOptions) => {
    const defaultOptions = { style: 'decimal' } satisfies NumberFormatOptions;

    return Intl.NumberFormat(currentIso.value, assign(defaultOptions, options)).format(value);
  };

  const formatDate = (date: Date, options?: DateTimeFormatOptions) => {
    const defaultOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    } satisfies DateTimeFormatOptions;

    return new Intl.DateTimeFormat(currentIso.value, assign(defaultOptions, options)).format(date);
  };

  const formatDateRange = (startDate: Date, endDate: Date, options?: {
    includeYear?: boolean;
  }) => {
    const start = formatDate(startDate, {
      day: '2-digit',
      month: '2-digit',
      year: options?.includeYear
        ? '2-digit'
        : undefined,
    });

    const end = formatDate(endDate, {
      day: '2-digit',
      month: '2-digit',
      year: options?.includeYear
        ? '2-digit'
        : undefined,
    });

    return `${start} - ${end}`;
  };

  const formatPercentage = (value: number, options?: NumberFormatOptions) => {
    const defaultOptions = {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      style: 'percent',
    } satisfies NumberFormatOptions;

    return Intl.NumberFormat(currentIso.value, assign(defaultOptions, options)).format(value);
  };

  const formatTimeDistance = (date: Date, baseDate: Date) => formatDistance(date, baseDate, {
    addSuffix: true,
    locale: dateFnsLocaleMap[locale.value as LocaleCode] ?? dateFnsDe,
  });

  return {
    formatCurrency,
    formatDate,
    formatDateRange,
    formatNumber,
    formatPercentage,
    formatTimeDistance,
  };
};

export { useFormatter };
