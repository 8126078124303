import { type IsoLocale } from '../config';

enum Currency {
  CHF = 'CHF',
  EUR = 'EUR',
  GBP = 'GBP',
}

const CURRENCY_BY_LOCALE: Record<IsoLocale, Currency> = {
  'de-AT': Currency.EUR,
  'de-CH': Currency.CHF,
  'de-DE': Currency.EUR,
  'de-IT': Currency.EUR,
  'de-LI': Currency.CHF,
  'de-LU': Currency.EUR,
  'en-GB': Currency.GBP,
  'es-ES': Currency.EUR,
  'fr-BE': Currency.EUR,
  'fr-CH': Currency.CHF,
  'fr-FR': Currency.EUR,
  'fr-LU': Currency.EUR,
  'it-CH': Currency.CHF,
  'it-IT': Currency.EUR,
  'nl-BE': Currency.EUR,
  'nl-NL': Currency.EUR,
};

const getCurrency = (currentIso?: IsoLocale): Currency => CURRENCY_BY_LOCALE[currentIso || 'de-DE'] || Currency.EUR;

export {
  Currency,
  getCurrency,
};
